<template>
  <div
    class="d-flex small-content-height flex-column align-center justify-center"
  >
    <div v-if="exam" class="pa-4" style="max-width: 1124px; width: 100%">
      <div class="small-title">
        {{ $t("cau_hoi") }} {{ exam.order }}
        <span v-if="totalQuestion">/ {{ totalQuestion }}</span>
      </div>
      <v-card class="py-2 mb-6 question" style="overflow: hidden">
        <div class="px-6" style="width: 100%; overflow-y: auto">
          <img :src="exam.question.content" alt="" />
          <div class="watermark select-none">
            <span v-for="i in 24" :key="i">{{ watermark }}</span>
          </div>
        </div>

        <div class="px-6" style="width: 100%; overflow-x: auto">
          <div v-if="exam.multiSelect">
            <div
              class="d-flex align-center mb-2"
              v-for="item in exam.choices"
              :key="item.order"
            >
              <v-checkbox
                class="mt-0"
                v-model="answers"
                :value="item.order"
                hide-details
              >
              </v-checkbox>
              <span style="font-weight: bold; margin-right: 10px;margin-top:3px"
                >{{ alphabet[item.order - 1] }}.</span
              >
              <div v-if="item.type === 'text'" style="margin-top:0">
                {{ item.content }}
              </div>
              <div v-else-if="item.type === 'image'" style="margin-top:5px">
                <img :src="item.image" alt="" />
              </div>
            </div>
          </div>
          <v-radio-group v-model="answers[0]" v-else>
            <div
              v-for="item in exam.choices"
              class="d-flex align-center"
              :key="item.order"
            >
              <v-radio class="mt-2" :value="item.order"></v-radio>
              <span style="font-weight: bold; margin-right: 10px"
                >{{ alphabet[item.order - 1] }}.</span
              >
              <div v-if="item.type === 'text'">{{ item.content }}</div>
              <div v-else-if="item.type === 'image'">
                <img :src="item.image" alt="" />
              </div>
            </div>
          </v-radio-group>
        </div>

        <div v-if="showWarn" class="red--text" style="font-weight: bold">
          {{ $t("chon_cau_tra_loi") }}
        </div>
      </v-card>
      <v-layout align-center>
        <v-btn
          v-if="
            totalQuestion &&
              exam &&
              String(totalQuestion) === String(exam.order)
          "
          :loading="loading"
          color="primary"
          @click="submitAnswerSubmit"
        >
          <v-icon left>mdi-check</v-icon>
          {{ $t("ket_thuc") }}
        </v-btn>
        <v-btn
          v-else
          :loading="loading"
          color="secondary"
          @click="submitAnswerSubmit"
        >
          <v-icon left>mdi-chevron-right</v-icon>
          {{ $t("tiep_theo") }}
        </v-btn>
        <div class="red--text" style="font-weight: bold">
          {{ $t("canh_bao") }}
        </div>
      </v-layout>
    </div>
    <v-card
      v-else-if="exam === null"
      class="d-flex flex-column align-center pa-10"
      style="width: 100%; max-width: 500px"
    >
      <img class="py-10" src="/images/success.png" width="200px" alt="" />
      <div style="font-weight: bold; font-size: 20px">
        {{ $t("chuc_mung") }}
      </div>
      <div v-if="score !== undefined" class="mt-2" style="font-size: 16px">
        {{ $t("lam_dung") }}
        <span style="font-weight: bold"> {{ score }}</span> /
        <span style="font-weight: bold">{{ total }}</span> câu
      </div>
    </v-card>
  </div>
</template>

<script>
import {
  getQuestion,
  getScore,
  getTotalQuestion,
  submitAnswer,
} from "@/api/exam";
import { mapState } from "vuex";
import { currentExamination } from "@/api/student-test";

export default {
  data: () => ({
    exam: undefined,
    alphabet: ["A", "B", "C", "D", "E", "F", "G", "H"],
    answers: [],
    showWarn: false,
    score: undefined,
    total: undefined,
    totalQuestion: undefined,
    loading: false,
    watermark: undefined,
    studentCode: undefined,
  }),
  computed: {
    ...mapState("student", ["examination", "formId"]),
    reverseWatermark() {
      return this.watermark
        .split("")
        .reverse()
        .join("");
    },
  },
  async mounted() {
    if (!this.formId) {
      await this.getForm();
    }
    const result = await getTotalQuestion();
    this.totalQuestion = result.data;
    await this.getData();
    this.getWatermark();
  },
  methods: {
    required(e) {
      return !!e || "Trường này là bắt buộc";
    },
    getWatermark() {
      const info = this.$store.state.student.info;
      if (info && info.studentCode) {
        this.watermark = info.studentCode || "0";
      } else {
        this.watermark = this.$store.state.user.user.studentId || "0";
      }
    },
    async getForm() {
      const {
        data: { examination, form, students, subjectName, info },
      } = await currentExamination();
      this.$store.commit("student/SET_EXAMINATION", examination);
      this.$store.commit("student/SET_STUDENTS", students);
      this.$store.commit("student/SET_SUBJECT_NAME", subjectName);
      if (form) {
        this.$store.commit("student/SET_FORM_ID", form);
        this.$store.commit("student/SET_INFO", info);
      }
    },
    async getData() {
      const { data } = await getQuestion();
      this.exam = data;
      await this.getScore();
    },
    async getScore() {
      if (!this.exam && this.examination.immediatelyScoring) {
        const {
          data: { score, total },
        } = await getScore({ formId: this.formId });
        this.score = score;
        this.total = total;
      }
    },
    async submitAnswerSubmit() {
      if (this.answers.length === 0) {
        this.showWarn = true;
        return;
      }
      this.loading = true;
      try {
        await submitAnswer({
          choices: this.answers,
          order: this.exam.order,
        });
        this.answers = [];
        this.showWarn = false;
        await this.getData();
      } catch (e) {
        if (e.response.data.error.code === 421) {
          this.exam = null;
          await this.getScore();
        }
      } finally {
        this.loading = false;
        this.getWatermark();
      }
    },
    // handleAnswersChange(e) {
    //   if (!this.exam.multiSelect) this.answers = [e[e.length - 1]];
    // }
  },
};
</script>

<style scoped>
.question > * {
  margin-bottom: 10px;
}

.watermark {
  position: absolute;
  width: 200%;
  top: -25%;
  left: -25%;
  font-size: 90px;
  opacity: 0.1;
  font-weight: bold;
  filter: blur(2px);
  z-index: 100;
  pointer-events: none;
  color: #b2b2b2;
  text-align: center;
  transform: rotate(-25deg);
}

.watermark > span {
  display: inline-block;
  margin-right: 40px;
}

.select-none {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}
</style>
